<template>
  <b-modal
    @show="resetUser"
    id="inviteUser"
    ref="inviteUser"
    size="lg"
    title="Invite User"
    no-close-on-esc
    no-close-on-backdrop
  >
    <b-form-group
      label="Email"
      label-for="email"
      :label-cols="3"
      :horizontal="true"
    >
      <b-form-input
        id="email"
        type="email"
        placeholder="Email"
        v-model="userEmail"
        autocomplete="off"
        :class="{
          'is-invalid': $v.user.email.$error,
          'is-valid': !$v.user.email.$invalid
        }"
      ></b-form-input>
      <b-form-invalid-feedback v-if="!$v.user.email.required"
        >Email can't be blank</b-form-invalid-feedback
      >
      <b-form-invalid-feedback v-if="!$v.user.email.email">
        Please provide a valid email address</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group
      label="User Role"
      label-for="user_role"
      :label-cols="3"
      :horizontal="true"
    >
      <b-form-select
        id="user_role"
        v-model="user.role_id"
        text-field="name"
        value-field="id"
        :options="roles"
      >
      </b-form-select>
    </b-form-group>
    <div class="form-row form-group">
      <div class="offset-3 col-9 text-danger pr-1" v-if="superAdminSelected">
        <i class="fa fa-exclamation-triangle"></i>
        This role is for FIN internal use only.
      </div>
    </div>
    <div v-if="superAdmin">
      <b-form-group label="Group Type" :label-cols="3" :horizontal="true">
        <b-form-radio-group
          id="client_group"
          class="pt-2"
          v-model="existingGroup"
          :options="groupOptions"
          @change="resetGroupInfo"
        >
        </b-form-radio-group>
      </b-form-group>

      <b-form-group
        label="User Group"
        label-for="user_group"
        :label-cols="3"
        :horizontal="true"
        v-if="existingGroup"
      >
        <b-form-select
          id="user_group"
          v-model="user.group_ids[0]"
          text-field="alias"
          value-field="id"
          :options="groups"
        >
        </b-form-select>
      </b-form-group>

      <b-form-group
        label="Client Group"
        label-for="client_group"
        :label-cols="3"
        :horizontal="true"
        v-else
      >
        <b-form-input
          id="client_group"
          type="text"
          placeholder=""
          v-model="clientGroup"
          autocomplete="off"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Group Company Type"
        label-for="client_group"
        :label-cols="3"
        :horizontal="true"
        v-if="!existingGroup"
      >
        <multiselect
          v-model="groupCompanyType"
          :options="companyTypes"
          :multiple="false"
          :close-on-select="true"
          placeholder="Select Company Type"
          group-label="type"
          group-values="companies"
          :class="[
            'simple-select group-select highlight-group-title form-control'
          ]"
          :group-select="false"
          :showLabels="false"
        />
      </b-form-group>
    </div>

    <div slot="modal-footer">
      <div class="row text-right">
        <div class="col-sm-12">
          <b-button
            size=""
            variant="secondary"
            class="fw-btn mr-2"
            @click="hideModal"
            >Cancel</b-button
          >
          <b-button
            type="submit"
            variant="primary"
            class="btn btn-primary fw-btn"
            @click="createUser"
            :disabled="$v.$invalid"
            >Invite</b-button
          >
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import { required, email } from 'vuelidate/lib/validators'
export default {
  name: 'InviteUser',
  components: {
    Multiselect
  },
  props: {
    roles: {
      type: Array,
      required: true
    },
    groups: {
      type: Array,
      required: false
    },
    superAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      user: {
        email: null,
        role_id: null,
        group_ids: [],
        is_approved: true
      },
      clientGroup: null,
      existingGroup: true,
      groupCompanyType: '',
      groupOptions: [
        { text: 'Existing Group', value: true },
        { text: 'New Client Group', value: false }
      ]
    }
  },
  computed: {
    ...mapGetters(['companyTypes']),
    userEmail: {
      get() {
        return this.user.email
      },
      set(email) {
        this.user.email = email.toLowerCase()
        this.$v.user.email.$touch()
      }
    },
    superAdminSelected() {
      return this.user.role_id === 1 //FIN Admin
    }
  },
  methods: {
    createUser() {
      if (this.superAdmin) {
        if (this.existingGroup && this.user.group_ids.length < 1) {
          Vue.toasted.show('Please select a valid client group.', {
            icon: 'chain-broken',
            type: 'error'
          })
          return
        }
        if (!this.existingGroup) {
          if (!this.clientGroup || this.clientGroup.trim() === '') {
            Vue.toasted.show('Please enter a valid client group name.', {
              icon: 'chain-broken',
              type: 'error'
            })
            return
          }
          if (!this.groupCompanyType || this.groupCompanyType === '') {
            Vue.toasted.show(
              'Please select a valid client group Company Type.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
            return
          }
        }
      }
      this.$http
        .post(
          '/api/group_users',
          {
            user: this.user,
            client_group: this.clientGroup,
            group_company_type: this.groupCompanyType
          },
          {
            handleErrors: true,
            params: {
              super_admin: this.superAdmin
            }
          }
        )
        .then(res => {
          if (res.data.user) {
            Vue.toasted.show('User invited. Email sent', {
              icon: 'user-circle',
              type: 'success'
            })
            this.$emit('userAdded')
            this.hideModal()
          } else {
            let message =
              res.data.message ||
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.'
            Vue.toasted.show(message, { icon: 'chain-broken', type: 'error' })
          }
        })
    },
    hideModal() {
      this.$refs.inviteUser.hide()
    },
    resetGroupInfo() {
      this.user.group_ids = []
      this.clientGroup = null
      this.groupCompanyType = ''
    },
    resetUser() {
      this.user.email = null
      this.user.role_id = null
      this.user.group_ids = []
      this.groupCompanyType = ''
      this.clientGroup = null
      this.existingGroup = true
      this.$v.$reset()
    }
  },
  validations: {
    user: {
      email: {
        required,
        email
      }
    }
  }
}
</script>

<style type="scss"></style>
