<template>
  <b-modal
    @show="resetUser"
    id="bulkInviteUsers"
    ref="bulkInviteUsers"
    size="lg"
    title="Invite Multiple Users"
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="mb-2">
      <p>
        Use the <b>Choose File</b> button below to select a csv file containing
        the list of <b>Email IDs</b>.
      </p>

      <p>
        The file should contain a <b>single column of Email IDs</b>. A column
        header is optional.
      </p>
      <p>The file must have the extension <b>.csv</b></p>
    </div>
    <b-form-group
      label="Emails"
      label-for="emails"
      :label-cols="3"
      :horizontal="true"
    >
      <input
        type="file"
        accept="text/csv"
        name="emailFile"
        id="emailFile"
        class="inputfile"
        ref="emailFile"
        @change="handleFileUpload()"
      />
    </b-form-group>
    <div class="form-row form-group" v-if="validFile">
      <div class="col-3 col-form-label">
        Uploaded Email ids
      </div>
      <div class="pl-2 col-3 col-form-label">
        <span>{{ uploadedEmails }}</span>
      </div>
    </div>
    <b-form-group
      label="User Role"
      label-for="user_role"
      :label-cols="3"
      :horizontal="true"
    >
      <b-form-select
        id="user_role"
        v-model="roleId"
        text-field="name"
        value-field="name"
        :options="roles"
      >
      </b-form-select>
    </b-form-group>
    <div class="form-row form-group">
      <div class="offset-3 col-9 text-danger pr-1" v-if="superAdminSelected">
        <i class="fa fa-exclamation-triangle"></i>
        This role is for FIN internal use only.
      </div>
    </div>
    <div v-if="superAdmin">
      <b-form-group label="Group Type" :label-cols="3" :horizontal="true">
        <b-form-radio-group
          id="client_group"
          class="pt-2"
          v-model="existingGroup"
          :options="groupOptions"
          @change="resetGroupInfo"
        >
        </b-form-radio-group>
      </b-form-group>

      <b-form-group
        label="User Group"
        label-for="user_group"
        :label-cols="3"
        :horizontal="true"
        v-if="existingGroup"
      >
        <b-form-select
          id="user_group"
          v-model="groupId"
          text-field="alias"
          value-field="id"
          :options="groups"
        >
        </b-form-select>
      </b-form-group>

      <b-form-group
        label="Client Group"
        label-for="client_group"
        :label-cols="3"
        :horizontal="true"
        v-else
      >
        <b-form-input
          id="client_group"
          type="text"
          placeholder=""
          v-model="clientGroup"
          autocomplete="off"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Group Company Type"
        label-for="client_group"
        :label-cols="3"
        :horizontal="true"
        v-if="!existingGroup"
      >
        <multiselect
          v-model="groupCompanyType"
          :options="companyTypes"
          :multiple="false"
          :close-on-select="true"
          placeholder="Select Company Type"
          group-label="type"
          group-values="companies"
          :class="[
            'simple-select group-select highlight-group-title form-control'
          ]"
          :group-select="false"
          :showLabels="false"
        />
      </b-form-group>
    </div>

    <div slot="modal-footer">
      <div class="row text-right">
        <div class="col-sm-12">
          <b-button
            size=""
            variant="secondary"
            class="fw-btn mr-2"
            @click="hideModal"
            >Cancel</b-button
          >
          <b-button
            type="submit"
            variant="primary"
            class="btn btn-primary fw-btn"
            @click="createUser"
            :disabled="disableInvite"
            >Invite</b-button
          >
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import Vue from 'vue'
// import Qs from 'qs'
export default {
  name: 'BulkInviteUsers',
  components: {
    Multiselect
  },
  props: {
    roles: {
      type: Array,
      required: true
    },
    groups: {
      type: Array,
      required: false
    },
    superAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uploadedIds: [],
      validFile: false,
      groupId: null,
      roleId: null,
      clientGroup: null,
      existingGroup: true,
      groupCompanyType: '',
      groupOptions: [
        { text: 'Existing Group', value: true },
        { text: 'New Client Group', value: false }
      ]
    }
  },
  computed: {
    ...mapGetters(['companyTypes']),
    validFormats() {
      return ['text/csv']
    },
    uploadedEmails() {
      return this.uploadedIds.length
    },
    disableInvite() {
      return !this.validFile || !this.roleId || this.uploadedEmails < 1
    },
    superAdminSelected() {
      return this.roleId === 'FIN Admin' //FIN Admin
    }
  },
  methods: {
    handleFileUpload() {
      let chosenFile = this.$refs.emailFile.files[0]

      if (this.validFormats.includes(chosenFile.type)) {
        this.validFile = true
        this.emailFile = chosenFile
        const reader = new FileReader()

        reader.readAsText(this.emailFile)

        reader.onload = () => {
          let data = reader.result
          let arr = data.split(/\r?\n/)
          let columns = arr[0].split(',')
          if (columns.length > 1) {
            this.$toasted.show(
              'Invalid file format, Please make sure file has only one column (Email ID)',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
            this.clearData()
          } else {
            let result = arr
            if (result && !result[0].includes('@')) result.shift()
            result = result.filter(email => email.trim().length > 0)
            this.uploadedIds = result
            if (this.uploadedEmails < 1) {
              this.$toasted.show(
                'Uploaded File is empty. Please make sure file has atleast one entry.',
                {
                  icon: 'chain-broken',
                  type: 'error'
                }
              )
              this.$refs.emailFile.value = null
              this.emailFile = null
              this.validFile = false
            } else this.validFile = true
          }
        }
      } else {
        this.$toasted.show('Invalid file format, please choose csv files...', {
          icon: 'chain-broken',
          type: 'error'
        })
        this.clearData()
      }
    },
    clearData() {
      this.emailFile = null
      this.validFile = false
      this.uploadedIds = []
    },
    createUser() {
      if (this.superAdmin) {
        if (this.existingGroup && !this.groupId) {
          Vue.toasted.show('Please select a valid client group.', {
            icon: 'chain-broken',
            type: 'error'
          })
          return
        }
        if (!this.existingGroup) {
          if (!this.clientGroup || this.clientGroup.trim() === '') {
            Vue.toasted.show('Please enter a valid client group name.', {
              icon: 'chain-broken',
              type: 'error'
            })
            return
          }
          if (!this.groupCompanyType || this.groupCompanyType === '') {
            Vue.toasted.show(
              'Please select a valid client group Company Type.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
            return
          }
        }
      }
      this.$http
        .post('/api/admin/users/bulk_user_invite', {
          emails: this.uploadedIds,
          group_id: this.groupId,
          role: this.roleId,
          group_name: this.clientGroup,
          group_company_type: this.groupCompanyType
          //super_admin: this.superAdmin
        })
        .then(res => {
          if (res.data.result) {
            Vue.toasted.show(
              'Bulk User Invite initiated. Completion status will be notified by email.',
              {
                icon: 'user-circle',
                type: 'success'
              }
            )
            // let count = parseInt(res.data.result.users_created)
            // if (count && count > 0) {
            //   Vue.toasted.show(
            //     'Invite email successfully sent to ' +
            //       count +
            //       '/' +
            //       this.uploadedEmails +
            //       ' users.',
            //     {
            //       icon: 'user-circle',
            //       type: 'success'
            //     }
            //   )
            // } else {
            //   Vue.toasted.show(
            //     'Users already exist or No valid emails to send Invite.',
            //     {
            //       icon: 'chain-broken',
            //       type: 'error'
            //     }
            //   )
            // }
            // this.$emit('userAdded')
            this.hideModal()
          } else {
            let message =
              res.data.message ||
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.'
            Vue.toasted.show(message, { icon: 'chain-broken', type: 'error' })
          }
        })
    },
    hideModal() {
      this.$refs.bulkInviteUsers.hide()
    },
    resetGroupInfo() {
      this.groupId = null
      this.clientGroup = null
    },
    resetUser() {
      this.roleId = null
      this.groupId = null
      this.emailFile = null
      this.validFile = false
      this.uploadedIds = []
      this.clientGroup = null
      this.existingGroup = true
    }
  }
}
</script>

<style type="scss"></style>
